.react-responsive-modal-closeButton {
  top: 5px;
  right: 5px;
  outline: none;
}

svg {
  border: none;
  width: 20px;
  height: 20px;
}
.react-responsive-modal-modal {
  border-radius: 5px;
  background-color: #f6f7f9;
}

.react-responsive-modal-overlay {
  background: rgba(0, 0, 0, 0.55);
}

/* width */
::-webkit-scrollbar {
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  // background: #f1f1f1;
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.__react_component_tooltip.show {
  visibility: visible !important;
  @media only screen and (max-width: 839px) {
    visibility: hidden !important;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    visibility: hidden !important;
  }
}

// Remove blue highlight over html image/div tag when clicked in mobile
div,
img {
  -webkit-tap-highlight-color: transparent;
  outline: transparent;
}
