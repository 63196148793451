@font-face {
  font-family: "Open Sans";
  font-display: swap;
  src: url("/OpenSans/OpenSans.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@mixin scrollbars($size, $foreground-color, $hover-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $hover-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
html,
body {
  margin: 0;
  height: 100%;
  font-family: "Open Sans";
}
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Open Sans";
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  // width: 100%;
  background-color: #dddddd;
  // z-index: 99;
  @media only screen and (max-width: 839px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 120px;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: grid;
    grid-template-columns: 1fr 200px;
    grid-template-rows: auto;
    height: 100%;
  }
}

.thewiewerwindow {
  background-color: #fff;
  box-shadow: inset 0 0 10px #000000;
  position: relative;
  width: 70%;
  height: 85%;
  margin-left: 20px;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}

.eon_access_logo {
  position: absolute;
  top: 60px;
  left: 40px;
  color: #fff;
  text-align: center;
  z-index: 1;
  object-fit: cover;
  width: 200px;
  @media only screen and (max-width: 839px) {
    left: auto;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    position: absolute;
    top: 25px;
    left: 20px;
    color: #fff;
    text-align: center;
    z-index: 1;
    object-fit: cover;
    width: 120px;
  }
}

.viewer_window_header {
  position: absolute;
  bottom: 165px;
  color: #0f0f0f;
  font-size: 20px;
  text-align: center;
  z-index: 1;
  height: 0;
  @media only screen and (max-width: 839px) {
    bottom: 110px;
    color: #0f0f0f;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    top: 17px;
  }
}

.rendercanvas {
  position: absolute;
  top: 60px;
  left: 25px;
  width: 90%;
  height: 80%;
  display: inline-block;
  outline: none;
  z-index: 0;
}

.Progress_Status {
  position: absolute;
  top: 50%;
  bottom: 0%;
  left: 25%;
  right: 25%;
  width: 50%;
  border: 1px solid #fff;
  border-radius: 2px;
  background-color: transparent;
  height: 20px;
}

.myprogressBar {
  width: 1%;
  height: 20px;
  background-color: #1babe2;
  text-align: center;
  line-height: 20px;
  color: #fff;
  font-size: 16px;
}

.viewer_not_found {
  position: absolute;
  top: 50%;
  bottom: 0%;
  left: 25%;
  right: 25%;
  width: 50%;
  text-align: center;
  display: none;
}
.viewer_not_found_text {
  font-size: 16px;
}


.steps_container {
  position: absolute;
  bottom: 105px;
  left: 50px;
  width: calc(100% - 100px);
  display: grid;
  height: 60px;
  padding-bottom: 5px;
  overflow-x: auto;
  overflow-y: visible;
  @include scrollbars(5px, #1babe2, #1997c8 ,rgba(100, 100, 100, 0.2));
  @media only screen and (max-width: 839px) {
    display: none;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: none;
  }
}

.steps_content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step {
  background-color: #1babe2;
  height: 5px;
  width: 100%;
  min-width: 31px;
  margin-inline-start: 3px;
  cursor: pointer;
}


.emptyStep {
  background-color: transparent !important;
  height: 5px;
  width: 100%;
  min-width: 31px;
  margin-inline-start: 3px;
  cursor: default;
  z-index: -1;
}

.passiveAligners {
  background-color: #cccccc !important;
  height: 5px;
  width: 100%;
  min-width: 31px;
  margin-inline-start: 3px;
  cursor: pointer;
}

.firstStep {
  background-color: #1babe2;
  height: 5px;
  margin-inline-start: 3px;
  cursor: pointer;
  width: 100%;
  min-width: 31px;
}

.overCorrectionStepsNumber {
  background-color: #e84e1b !important;
}


.labels_content {
  position: relative;
  width: 100%;
  height: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tooltip_orange {
  position: absolute;
  top: -27px;
  background-color: #aaa9a8;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.tooltip_white {
  position: absolute;
  top: -24px;
  left: 3px;
  background-color: #1babe2;
  border: 2px solid #fff;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  z-index: 999;
}

.labels_content .tooltip_orange::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  margin-top: -1px;
  border-style: solid;
  border-color: #aaa9a8 transparent transparent transparent;
}
.label {
  height: 15px;
  width: 100%;
  color: #aaa9a8;
  font-size: 14px;
  text-align: center;
  margin-inline-start: 3px;
  cursor: pointer;
  min-width: 25px;
}

.play_pause_icon {
  z-index: 1;
  cursor: pointer;
  object-fit: cover;
  width: 40px;
  height: 40px;
  justify-self: center;
  @media only screen and (max-width: 839px) {
    display: none;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: none;
  }
}
.sid_bar_container {
    background-color: rgba($color: #000000, $alpha: 0);
    position: absolute;
    right: 5%;
    top: 32px;
    z-index: 1;
  @media only screen and (max-width: 839px) {
    display: grid;
    align-items: center;
    padding: 0px 20px;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    background-color: rgb(51, 51, 51);
    display: block;
    align-items: start;
    padding: 0px;
  }
}
.side_bar_header {
  padding: 20px 10px;
  color: #c0c0c0;
  font-size: 16px;
  text-align: center;
  @media only screen and (max-width: 839px) {
    display: none;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    position: relative;
    padding: 20px 10px;
    color: #c0c0c0;
    font-size: 16px;
    text-align: center;
    display: block;
  }
}
.right_panel_container {
  width: 100%;
  display: grid;
  align-content: start;
  padding: 50px 0px;
  padding-bottom: 20px;
  text-align: start;
  @media only screen and (max-width: 839px) {
    display: none;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: none;
  }
}

.spinner_content_title {
  display: block;
  width: 40px;
  height: 40px;
  top: 75px;
  right: 140px;
  position: absolute;
}

.double_bounce1,
.double_bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #63d7c7;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.right_panel_content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 35px;
  padding: 0px 20px;
}

.right_panel_content_text {
  text-align: start;
  color: #fff;
  font-size: 14px;
}

.side_bar_header {
  padding: 20px 10px;
  color: #c0c0c0;
  font-size: 16px;
  text-align: center;
  @media only screen and (max-width: 839px) {
    display: none;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    position: relative;
    padding: 20px 10px;
    color: #c0c0c0;
    font-size: 16px;
    text-align: center;
    display: block;
  }
}

.icons_container {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
  width: 100%;
  @media only screen and (max-width: 839px) {
    grid-template-columns: 70px 70px 70px 70px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    width: 100%;
  }
}

.icons_content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 35px;
  padding: 0px 20px;
  margin: 0px 10px;
  align-items: center;
  cursor: pointer;
  @media only screen and (max-width: 839px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    grid-gap: unset;
    padding: 0px 0px 14px;
    margin: 0;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 10px;
    padding: 0px 20px;
    margin: 0px 10px;
    align-items: center;
  }
}

.icon {
  width: 65px;
  object-fit: cover;
  @media only screen and (max-width: 839px) {
    margin-right: auto;
    margin-left: auto;
    width: 45px;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    width: 45px;
    height: 45px;
    object-fit: cover;
  }
}
.icon_title {
  color: #fff;
  font-size: 16px;
  @media only screen and (max-width: 839px) {
    font-size: 14px;
    text-align: center;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    color: #fff;
    font-size: 14px;
    text-align: start;
  }
}

.double_bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.spinner {
  display: block;
  width: 40px;
  height: 40px;
  top: 45%;
  position: absolute;
  display: none;
}
.loading_title {
  top: 45%;
  position: absolute;
  width: 300px;
  text-align: center;
  margin-top: 50px;
  font-size: 12px;
}

.hidden_on_desktop {
  display: none;
  @media only screen and (max-width: 839px) {
    display: flex;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: grid;
  }
}

.activeStep {
 border: 2px solid rgba($color: #000000, $alpha: 0.6)
}

.active {
  width: 13px;
  height: 13px;
  background: #fff !important;
  border-radius: 50%;
  border: 7px solid #6bcaba !important;
}

.labels_content {
  width: 100%;
  max-width: 100%;
  height: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.play_pause_container {
    position: absolute;
    z-index: 1;
    bottom: 35px;
    display: grid;
    grid-template-columns: 50px 50px 50px 50px 50px;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    position: absolute;
    z-index: 1;
    bottom: 25px;
    display: grid;
    grid-template-columns: 40px 50px 60px 50px 40px;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
  }
}

.first_step {
  @media only screen and (max-width: 839px) {
    position: relative;
    z-index: 1;
    background-color: #ccc;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    position: relative;
    z-index: 1;
    background-color: #ccc;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.first_step_active {
  @media only screen and (max-width: 839px) {
    position: relative;
    z-index: 1;
    background-color: #6bcaba;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    position: relative;
    z-index: 1;
    background-color: #6bcaba;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.arrow_left_first_step {
  @media only screen and (max-width: 839px) {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    border-radius: 3px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    font-weight: bold;
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 11px);
    margin-inline-start: 3px;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    border-radius: 3px;
    /* margin-left: 2px; */
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    font-weight: bold;
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 11px);
    margin-inline-start: 3px;
  }
}

.arrow_left_first_step_1 {
  @media only screen and (max-width: 839px) {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    border-radius: 3px;
    /* margin-left: 2px; */
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    font-weight: bold;
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 4px);
    margin-inline-start: 3px;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    border-radius: 3px;
    /* margin-left: 2px; */
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    font-weight: bold;
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 4px);
    margin-inline-start: 3px;
  }
}

.backward_icon {
  @media only screen and (max-width: 839px) {
    position: relative;
    z-index: 1;
    background-color: #ccc;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    position: relative;
    z-index: 1;
    background-color: #ccc;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

.backward_icon_active {
  @media only screen and (max-width: 839px) {
    position: relative;
    z-index: 1;
    background-color: #6bcaba;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    position: relative;
    z-index: 1;
    background-color: #6bcaba;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
.arrow_left {
  @media only screen and (max-width: 839px) {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    border-radius: 3px;
    margin-left: 5px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    font-weight: bold;
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    border-radius: 3px;
    margin-left: 5px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    font-weight: bold;
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }
}

.play_pause_icon_mobile {
  @media only screen and (max-width: 839px) {
    z-index: 1;
    object-fit: cover;
    width: 60px;
    height: 60px;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    z-index: 1;
    object-fit: cover;
    width: 60px;
    height: 60px;
  }
}

.move_forward_icon {
  @media only screen and (max-width: 839px) {
    position: relative;
    z-index: 1;
    background-color: #ccc;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    position: relative;
    z-index: 1;
    background-color: #ccc;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

.move_forward_icon_active {
  @media only screen and (max-width: 839px) {
    position: relative;
    z-index: 1;
    background-color: #6bcaba;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    position: relative;
    z-index: 1;
    background-color: #6bcaba;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

.arrow_right {
  @media only screen and (max-width: 839px) {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    border-radius: 3px;
    margin-left: 5px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    font-weight: bold;
    position: absolute;
    top: calc(50% - 10px);
    right: calc(50% - 5px);
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    border-radius: 3px;
    margin-left: 5px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    font-weight: bold;
    position: absolute;
    top: calc(50% - 10px);
    right: calc(50% - 5px);
  }
}

.last_step {
  @media only screen and (max-width: 839px) {
    position: relative;
    z-index: 1;
    background-color: #ccc;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    position: relative;
    z-index: 1;
    background-color: #ccc;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.last_step_active {
  @media only screen and (max-width: 839px) {
    position: relative;
    z-index: 1;
    background-color: #6bcaba;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    position: relative;
    z-index: 1;
    background-color: #6bcaba;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.arrow_right_last_step {
  @media only screen and (max-width: 839px) {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    border-radius: 3px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    font-weight: bold;
    position: absolute;
    top: calc(50% - 7px);
    right: calc(50% - 8px);
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    border-radius: 3px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    font-weight: bold;
    position: absolute;
    top: calc(50% - 7px);
    right: calc(50% - 8px);
  }
}

.arrow_right_last_step_1 {
  @media only screen and (max-width: 839px) {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    border-radius: 3px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    font-weight: bold;
    position: absolute;
    top: calc(50% - 7px);
    right: calc(50%);
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    border-radius: 3px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    font-weight: bold;
    position: absolute;
    top: calc(50% - 7px);
    right: calc(50%);
  }
}

/* Modal Content */

.modal_title {
  @media only screen and (max-width: 839px) {
    text-align: center;
    color: #000;
    font-size: 18px;
    padding: 10px 0px 20px;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    text-align: center;
    color: #000;
    font-size: 18px;
    padding-bottom: 30px;
  }
}

.title_content {
  @media only screen and (max-width: 839px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 15px;
    font-size: 14px;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 15px;
    font-size: 14px;
  }
}

.modal_subTitle {
  @media only screen and (max-width: 839px) {
    position: relative;
    display: inline-block;
    margin-right: auto;
    margin-left: auto;
    word-break: break-word;
    width: 100%;
    text-align: start;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    position: relative;
    display: inline-block;
    margin-right: auto;
    margin-left: auto;
    word-break: break-word;
    width: 100%;
    text-align: start;
  }
}

.side_bottom {
  @media only screen and (max-width: 839px) {
    padding-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 100%;
    border-bottom: 2px solid #e5e5e5;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    padding-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 100%;
    border-bottom: 2px solid #e5e5e5;
  }
}

.modal_hidden_on_desktop {
  display: none;
  @media only screen and (max-width: 839px) {
    display: block;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: block;
  }
}



.frontView {
  position: absolute;
  right: 60px;
  top: 45px;
  border-radius: 50%;
  cursor: pointer;
  height: 45px;
}

.upperView {
  position: absolute;
  right: 60px;
  top: 0px;
  cursor: pointer;
  // border: #000 1px solid;
  //border-radius: 50%;
  height: 45px;

}

.lowerView {
  cursor: pointer;
  // border: #000 1px solid;
  // border-radius: 50%;
  position: absolute;
  right: 60px;
  bottom: -140px;
  height: 45px;
}

.rightView {
  // border: #000 1px solid;
  // border-radius: 50%;
  position: absolute;
  right: 5px;
  top: 57px;
  cursor: pointer;
  height: 45px;
}

.leftView {
  // border: #000 1px solid;
  // border-radius: 50%;
  position: absolute;
  right: 140px;
  top: 55px;
  cursor: pointer;
  height: 45px;
}


.attachment {
  position: absolute;
  right: 5px;
  top: 165px;
  cursor: pointer;
  height: 35px;
  width: 168px;
  background-color: #e9e9e9;
  border: none;
  box-shadow: inset 0 0 5px #2a2a2a;
  color: #2a2a2a;
  border-radius: 16px;
}
.attachment_active {
  position: absolute;
  right: 5px;
  top: 165px;
  cursor: pointer;
  height: 35px;
  width: 168px;
  border: none;
  box-shadow: inset 0 0 5px #2a2a2a;
  background-color: #1babe2;
  color: #fff;
  border-radius: 16px;
}

.btn {
  z-index: 1;
  cursor: pointer;
  object-fit: cover;
  width: 40px;
  height: 40px;
  justify-self: center;
}
.btn_after {
  z-index: 1;
  cursor: pointer;
  object-fit: cover;
  width: 40px;
  height: 40px;
  justify-self: center;
}
